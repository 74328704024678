a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

#basic-nav-dropdown {
  color: black;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar {
  background-color: rgb(255, 255, 255);
}

#navspacer {
  background-image: linear-gradient(white, rgb(206, 206, 206), white);
  width: 100%;
  height: 3px;
  margin-bottom: 20px;
}

#logo-text {
  color: green;
}