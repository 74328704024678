/* Provide sufficient contrast against white background */
a {
  color: var(--main-link-color-primary);
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
  background-color: var(--main-bg-color);
}

.page-footer {
  background-color: #f1e7e7;
}

:root {
  --main-bg-color: #F4F2F3;
  --main-link-bg-color: #e4d5da;
  --main-link-color-primary: #64766a;
  --main-link-color-secondary: #94A7AE;
}